<template>
  <div>
      <Header />
      <div class="container">
        <h2>User Activity</h2>
        <div>
            <Toolbar class="p-mb-2" v-for="{id, name, active, lastLive} in users" :key="id">
                <template #left>
                    <div class="font-black" >{{name}}</div>
                </template>

                <template #right>
                    <div>
                        <div class="font-black last-activity">{{active ? "Online since" : "Last seen"}} {{moment(lastLive.toDate()).fromNow()}}</div><div class="activity" :class=" active ? 'user-active' : 'user-inactive'"></div>
                    </div>
                </template>
            </Toolbar>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Toolbar from 'primevue/toolbar';
import moment from 'moment'
import {useLoadUsers} from '@/fbDomain'
export default {
    name: 'Activity',
    components: {Header,Toolbar},
    methods: {
        moment,
        // update(cTime){
        //     setInterval(moment(cTime.toDate()).fromNow(), 1000);
        // }
    },
    setup() {
        const users = useLoadUsers()
        return {users}
    },
}
</script>

<style>
    .activity{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
    }
    .last-activity{
        position: relative;
        display: inline-block;
        margin-right: 10px;
        top: -3px;
    }
    .user-active{
        background-color: greenyellow;
    }
    .user-inactive{
        background-color: red;
    }
</style>